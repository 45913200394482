import React from "react";

const AdditionalHelp = () => {
  return (
    <div className="bg-gray-50 rounded-lg">
      <div className="mx-auto max-w-7xl py-6 px-10 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-xl font-bold tracking-tight text-gray-900">
          <span className="block">Still need additional help?</span>
          <span className="block text-yellow-500">
            Get in touch with our support team
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href="https://featurefind.io/contact"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-400 px-5 py-3 text-base font-medium text-slate-800 hover:bg-yellow-500"
            >
              Contact Support
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalHelp;
