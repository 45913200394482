import { graphql } from "gatsby";
import React from "react";

import { getArticleCategory } from "../utils/get-article-category";
import AdditionalHelp from "../components/additional-help";
import Footer from "../components/footer";
import Header from "../components/header";
import Hero from "../components/hero";
import Metadata from "../components/metadata";

const Article = ({ data }) => {
  const article = data.markdownRemark;

  return (
    <>
      <Header />
      <Metadata title={`${article.frontmatter.title} - FeatureFind`} />
      <Hero />
      <div className="mx-auto max-w-4xl text-lg px-6 py-16">
        <h1>
          <span className="block text-center text-lg font-semibold text-yellow-600">
            {getArticleCategory(article.fields.slug)}
          </span>
          <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            {article.frontmatter.title}
          </span>
        </h1>
        <section
          className="mt-10 pb-14 prose prose-yellow prose-lg text-gray-500 mx-auto"
          dangerouslySetInnerHTML={{ __html: article.html }}
        />
        <AdditionalHelp />
      </div>
      <Footer />
    </>
  );
};

export default Article;

export const pageQuery = graphql`
  query ArticleBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      fields {
        slug
      }
    }
  }
`;
